import React from 'react';
import { Image } from 'react-bootstrap';
import Banner2 from '../components/Banner2';
import CasesBanner from '../images/cases-banner-2.svg';
import Layout from '../components/Layout';
import TitledBoxesWithBg from '../components/TitledBoxesWithBg';
import CasesPain1 from '../images/cases-pain-9.svg';
import CasesPain2 from '../images/cases-pain-10.svg';
import CasesPain3 from '../images/cases-pain-11.svg';
import CasesPain4 from '../images/cases-pain-12.svg';
import StepSolutions from '../components/StepSolutions';
import Step1 from '../images/step_3_1.svg';
import Step2 from '../images/step_3_2.svg';
import Step2Content from '../images/step_3_2-content.png';
import Step3 from '../images/step_3_3.svg';
import Step4 from '../images/step_3_4.svg';
import StepsTopper from '../images/steps-topper-3.png';

const boxDataList = [
  {
    title: '客户喜好评估缺乏客观性',
    content: `获客渠道分散，没有以宏观的角度来整合客户数据；\n无法根据客户的客观属性和行为，评判客户喜好和价值`,
    icon: CasesPain1,
    id: 'cases-1',
    bgColor: 'white',
  },
  {
    title: '内容和活动难以管理和宣发',
    content: `内容和活动素材众多，未统一管理和系统展现；\n宣发时未做来源记录和行为追踪，难以沉淀互动数据`,
    icon: CasesPain2,
    id: 'cases-2',
    bgColor: 'yellow',
  },
  {
    title: '缺乏自动培育和筛选机制',
    content: `无法根据客户的喜好，自动且精准地向客户推送内容；\n人工筛选线索和区分优先级，耗费人力且效率低`,
    icon: CasesPain3,
    id: 'cases-3',
    bgColor: 'yellow',
  },
  {
    title: '投放效果难以全链路评估',
    content: `无法标记和追踪广告带来的线索，追踪最终转化；\n难以全链路评估投放获客效率，关注完整闭环`,
    icon: CasesPain4,
    id: 'cases-4',
    bgColor: 'white',
  },
];
function StepImage2() {
  return (
    <div className="step-2-image-container">
      <Image src={Step2} className="img-fluid" />
      <Image src={Step2Content} className="img-fluid content-png" />
    </div>
  );
};

const stepData = {
  titles: [
    `全局客户触点接入，\n宏观且客观评估客户喜好`,
    `营销内容中心化管理，\n有效沉淀客户喜好`,
    `自动筛选并培育线索，\n促进商机成熟`,
    `追踪投放最终转化，\n全链路评估获客效率`,
  ],
  images: [
    Step1,
    <StepImage2 />,
    Step3,
    Step4,
  ],
  classNames: ['', 'step_3_2', '', ''],
  contents: [
    [
      '为了帮助企业更宏观地了解客户，我们为企业接入了社交媒体（微信公众号、企业微信等）、官网、投放平台、邮件、直播平台等全局客户触点。',
      '客户在这些平台上留过的信息、做过的行为（浏览、点击、下载等）都会被一一记录，并以行为标签和内容标签的形式，在客户和对应内容上做标记。帮助企业通过客户所拥有的标签和标记次数等客观事实，判定客户的喜好。',
    ],
    [
      '我们为企业提供营销内容中心，企业可在内容中心中上传文档、视频成品内容，也可直接制作表单、H5微页面、落地页等设计创作内容。',
      '企业还可选择多项内容，制作内容集合页，将形成系列的内容实现矩阵展示。',
      '为了解客户常看的内容类型和浏览渠道，企业还可为每个内容和集合页配置来源和内容标签，生成传播链接。在自动工作流、公众号和企微自动回复中，都可快速引用传播链接。',
    ],
    [
      '我们为企业提供客户属性、事件、标签、来源等多重信息的自动筛选能力，企业可以选择多条筛选条件，并通过且/或规则自由组合，定向筛选培育人群。',
      '之后，企业可以通过自动化工作流向客户最常活跃的渠道，推送客户最感兴趣的内容，并将查看、下载、报名等事件记录分值，判断客户的意向度和商机成熟度。',
      '如此，市场部在提升营销合格线索数的同时，也能辅助销售判断优先级，降低成单周期。',
    ],
    [
      '我们为企业对接了百度SEM平台，企业的计划、单元、关键词可全局同步至荟聚系统，同时创建对应来源，客户通过广告链接进入企业官网时就会被记录来源并追踪。',
      '最终，为企业展示从消费 → 展现 → 点击 → 访问 → MIL → MQL → SQL的全链路转化数据，评估SEM的获客成本和效率。',
      '另外，我们还提供更多营销分析，企业可对SEM渠道进行注册时间分布、转化关键词分布等分析，为SEM策略优化奠定更充实的数据基础。',
    ],
  ],
};

export default () => (
  <Layout current="solutions" subCurrent="enterprise-service">
    <Banner2
      title="企业服务行业"
      Ptitle3="高效培育线索，促进商机成熟"
      backgroundImage={CasesBanner}
      showDemoBtn
      buttonText="咨询行业方案"
    />
    <TitledBoxesWithBg
      title="营销痛点与挑战"
      description="企业服务行业对线索质量要求较高，若线索未成熟就被交到销售手上，容易造成线索流失。市场部需要根据客户的喜好进行预培育，加速商机成熟，以增加合格线索数。但往往会遇到以下问题："
      boxDataList={boxDataList}
    />
    <StepSolutions data={stepData} stepsTopper={StepsTopper} />
  </Layout>
);
